import type { LayerProps } from 'react-map-gl';

import { COLORS } from '../Misc/consts';
import { TBuildingPointGeojson, TViewMode } from 'dippa-shared';
import { BUILDINGS_3D_ZOOM_BOUNDARY, BUILDINGS_ZOOM_BOUNDARY, MUNICIPALITIES_ZOOM_BOUNDARY } from './Map';
import { interpolateColor } from '../Misc/commonFunctions';


const formatFilter = (filters: Array<any>) => {
  if (!filters.length) return ["all"]; // NOTE: There's a bug in outline layer. Undefined isn't accepted
  return [
    "all",
    //['!', ['has', 'point_count']]
  ].concat(filters)
}


const radiusInterpolator = (multipl: number, showOwnedBuildings: boolean): any => {
  if (showOwnedBuildings) {
    multipl *= 1.5;
  }
  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    12,
    2.9 * multipl,
    15,
    5.5 * multipl
  ]
}


export const getBuildingLayer = (
  buildingLayerFilters: Array<any>,
  viewMode: TViewMode,
  showOwnedBuildings: boolean
): LayerProps => {
  //console.log(formatFilter(buildingLayerFilters))
  const opacity: any = showOwnedBuildings ? 1 : [
    'interpolate',
    ["exponential", 0.000002],
    ['zoom'],
    BUILDINGS_3D_ZOOM_BOUNDARY,
    viewMode === "buildings" ? 1 : 0,
    BUILDINGS_3D_ZOOM_BOUNDARY + 0.2,
    [
      "case",
      ["==", ["get", "hasPolygon"], true],
      0,
      1
    ]
  ];

  return (
    {
      id: 'building-points',
      type: 'circle',
      source: 'geojson-buildings',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      filter: formatFilter(buildingLayerFilters),
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          COLORS.eluokat['N/A']
        ],
        'circle-radius': radiusInterpolator(1.15, showOwnedBuildings),
        'circle-stroke-width': radiusInterpolator(0.42, showOwnedBuildings),
        'circle-stroke-color': "#000000",
        "circle-opacity": opacity,
        "circle-stroke-opacity": opacity
      }
    }
  )
};


export const getBuilding3dLayer = (
  buildingLayerFilters: Array<any>,
  selectedBuilding: TBuildingPointGeojson["features"][0] | null
): LayerProps => {
  return ({
    'id': 'buildings-3d',
    'source': 'geojson-3d-buildings',
    'type': 'fill-extrusion',
    'minzoom': BUILDINGS_3D_ZOOM_BOUNDARY - 0.25,
    filter: formatFilter(buildingLayerFilters),
    'paint': {
      'fill-extrusion-color': [
        'match', ['get', "eluokka"],
        'A', COLORS.eluokat["A"],
        'B', COLORS.eluokat["B"],
        'C', COLORS.eluokat["C"],
        'D', COLORS.eluokat["D"],
        'E', COLORS.eluokat["E"],
        'F', COLORS.eluokat["F"],
        'G', COLORS.eluokat["G"],
        COLORS.eluokat['N/A']
      ],
      'fill-extrusion-height': ["get", "korkeus"],
      'fill-extrusion-opacity': [
        'interpolate',
        ["exponential", 2],
        ['zoom'],
        BUILDINGS_3D_ZOOM_BOUNDARY,
        0,
        BUILDINGS_3D_ZOOM_BOUNDARY + 0.2,
        0.95
      ]
    }
  })
};


export const getSelectedBuildingLayer = (
  buildingLayerFilters: Array<any>,
  viewMode: TViewMode,
  showOwnedBuildings: boolean
): Array<LayerProps> => {
  return ([
    {
      id: 'selected-building-1',
      type: 'circle',
      source: 'geojson-selected-building',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.4)
        ],
        'circle-radius': radiusInterpolator(4.5, showOwnedBuildings),
        "circle-opacity": viewMode === "buildings" ? 0.2 : 0
      }
    },
    {
      id: 'selected-building-2',
      type: 'circle',
      source: 'geojson-selected-building',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.4)
        ],
        'circle-radius': radiusInterpolator(3, showOwnedBuildings),
        "circle-opacity": viewMode === "buildings" ? 0.4 : 0
      }
    },
    {
      id: 'selected-building-3',
      type: 'circle',
      source: 'geojson-selected-building',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          COLORS.eluokat['N/A']
        ],
        'circle-radius': radiusInterpolator(1.5, showOwnedBuildings),
        'circle-stroke-width': radiusInterpolator(0.5, showOwnedBuildings),
        'circle-stroke-color': "#ffffff",
        "circle-opacity": viewMode === "buildings" ? 1 : 0,
        "circle-stroke-opacity": viewMode === "buildings" ? 1 : 0,
      }
    },
  ])
};


export const getTooltipBuildingLayer = (
  buildingLayerFilters: Array<any>,
  viewMode: TViewMode,
  showOwnedBuildings: boolean
): Array<LayerProps> => {
  return ([
    {
      id: 'tooltip-building-1',
      type: 'circle',
      source: 'geojson-tooltip-building',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.4)
        ],
        'circle-radius': radiusInterpolator(4.5, showOwnedBuildings),
        "circle-opacity": viewMode === "buildings" ? 0.2 : 0
      }
    },
    {
      id: 'tooltip-building-2',
      type: 'circle',
      source: 'geojson-tooltip-building',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.4)
        ],
        'circle-radius': radiusInterpolator(3, showOwnedBuildings),
        "circle-opacity": viewMode === "buildings" ? 0.4 : 0
      }
    },
    {
      id: 'tooltip-building-3',
      type: 'circle',
      source: 'geojson-tooltip-building',
      minzoom: showOwnedBuildings ? 0 : BUILDINGS_ZOOM_BOUNDARY,
      paint: {
        'circle-color': [
          'match', ['get', "eluokka"],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          COLORS.eluokat['N/A']
        ],
        'circle-radius': radiusInterpolator(1.5, showOwnedBuildings),
        'circle-stroke-width': radiusInterpolator(0.5, showOwnedBuildings),
        'circle-stroke-color': "#ffffff",
        "circle-opacity": viewMode === "buildings" ? 1 : 0,
        "circle-stroke-opacity": viewMode === "buildings" ? 1 : 0,
      }
    },
  ])
};


export const getPostalAreasLayer = (
  viewMode: TViewMode
): Array<LayerProps> => {
  const color: any = [
    'match', ['get', "medianEluokka"],
    'A', COLORS.eluokat["A"],
    'B', COLORS.eluokat["B"],
    'C', COLORS.eluokat["C"],
    'D', COLORS.eluokat["D"],
    'E', COLORS.eluokat["E"],
    'F', COLORS.eluokat["F"],
    'G', COLORS.eluokat["G"],
    COLORS.eluokat['N/A']
  ];
  return [
    {
      id: "postal-areas-fill",
      type: "fill",
      source: 'geojson-postal-areas',
      maxzoom: BUILDINGS_ZOOM_BOUNDARY,
      minzoom: MUNICIPALITIES_ZOOM_BOUNDARY - 1,
      paint: {
        'fill-color': color,
        'fill-opacity': viewMode === "postalAreas" ? 0.07 : 0
      }
    },
    {
      id: 'postal-areas-outline',
      type: 'line',
      source: 'geojson-postal-areas',
      maxzoom: BUILDINGS_ZOOM_BOUNDARY,
      minzoom: MUNICIPALITIES_ZOOM_BOUNDARY - 1,
      layout: {
        "line-sort-key": [
          'match', ['get', "medianEluokka"],
          'A', 9,
          'B', 8,
          'C', 7,
          'D', 6,
          'E', 5,
          'F', 4,
          'G', 3,
          2
        ]
      },
      paint: {
        "line-color": color,
        'line-width': 1.75,
        "line-opacity": viewMode === "postalAreas" ? 1 : 0
      }
    }
  ]
}


export const getMunicipalityAreasLayer = (
  viewMode: TViewMode
): Array<LayerProps> => {
  const color: any = [
    'match', ['get', "medianEluokka"],
    'A', COLORS.eluokat["A"],
    'B', COLORS.eluokat["B"],
    'C', COLORS.eluokat["C"],
    'D', COLORS.eluokat["D"],
    'E', COLORS.eluokat["E"],
    'F', COLORS.eluokat["F"],
    'G', COLORS.eluokat["G"],
    COLORS.eluokat['N/A']
  ];

  return [
    {
      id: "municipality-areas-fill",
      type: "fill",
      source: 'geojson-municipality-areas',
      maxzoom: MUNICIPALITIES_ZOOM_BOUNDARY + 0.25,
      paint: {
        'fill-color': color,
        'fill-opacity': viewMode === "municipalityAreas" ? 0.07 : 0
      }
    },
    {
      id: 'municipality-areas-outline',
      type: 'line',
      source: 'geojson-municipality-areas',
      maxzoom: MUNICIPALITIES_ZOOM_BOUNDARY + 0.25,
      layout: {
        "line-sort-key": [
          'match', ['get', "medianEluokka"],
          'A', 9,
          'B', 8,
          'C', 7,
          'D', 6,
          'E', 5,
          'F', 4,
          'G', 3,
          2
        ]
      },
      paint: {
        'line-color': color,
        'line-width': 1.75,
        "line-opacity": viewMode === "municipalityAreas" ? 1 : 0
      }
    }
  ]
}


export const DRAW_CONTROL_STYLES = [
  // ACTIVE (being drawn)
  // line stroke
  {
    "id": "gl-draw-line",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.niitty,
      "line-dasharray": [0.2, 2],
      "line-width": 2.5
    }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
    "paint": {
      "fill-color": COLORS.sitowise.niitty,
      "fill-outline-color": COLORS.sitowise.niitty,
      "fill-opacity": 0.1
    }
  },
  // polygon mid points
  {
    'id': 'gl-draw-polygon-midpoint',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']],
    'paint': {
      'circle-radius': 3,
      'circle-color': COLORS.sitowise.niitty
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    "id": "gl-draw-polygon-stroke-active",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.niitty,
      "line-dasharray": [0.2, 2],
      "line-width": 2.5
    }
  },
  // vertex point halos
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],],
    "paint": {
      "circle-radius": 5,
      "circle-color": "#FFF"
    }
  },
  // vertex points
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],],
    "paint": {
      "circle-radius": 3,
      "circle-color": COLORS.sitowise.niitty
    }
  },


  // INACTIVE
  // line stroke
  {
    "id": "gl-draw-line-inactive",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "false"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.kajo,
      "line-width": 2.5
    }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill-inactive",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
    "paint": {
      "fill-color": COLORS.sitowise.kajo,
      "fill-outline-color": COLORS.sitowise.kajo,
      "fill-opacity": 0.1
    }
  },
  // polygon outline
  {
    "id": "gl-draw-polygon-stroke-inactive",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.kajo,
      "line-width": 2.5
    }
  }
]