import { CircularProgress } from '@mui/material';
import React, { DragEvent, useState } from 'react';

export const FileDropArea = ({ onFileDrop }: { onFileDrop: (file: File) => Promise<void> }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(false);


  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };


  // Handle drag leave event
  const handleDragLeave = () => {
    setIsDragging(false);
  };


  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];

    setIsLoading(true);
    onFileDrop(droppedFile)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        position: 'relative',
        padding: '20px',
        border: isDragging ? '2px solid #4CAF50' : '2px dashed #999',
        textAlign: 'center',
        width: '200px',
        margin: 'auto',
      }}
    >
      {loading ? <CircularProgress
        disableShrink
        size={40}
        thickness={5.5}
      /> : null}

      <p>{loading ? "Lähetetään..." : "Tuo Excel-tiedosto"}</p>
    </div>
  );
}