import axios from 'axios';
import { TSetState } from './Context';
import { resetLocalStorage } from '../App';

// const api = axios.create({
//   baseURL: SERVER_URL,
// });

export const setAxiosInterceptor = (setMapboxToken: TSetState<string>) => {
  // axios.interceptors.request.use(
  //   (config) => {
  //     // Modify request before sending it
  //     return config; // Send the modified config
  //   },
  //   (error) =>{
  //     // Handle request error here
  //     return Promise.reject(error);
  //   }
  // );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear token and redirect to login
        resetLocalStorage();
        setMapboxToken('');
      }
      return Promise.reject(error);
    }
  );
};