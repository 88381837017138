import React, { useContext } from "react";
import { CircularProgress } from "@mui/material";

import { LoadingStatusContext } from "../Misc/Context";
import { COLORS } from "../Misc/consts";
import "./LoadingStatus.css";


export const LoadingStatus = () => {
  const loadingStatus = useContext(LoadingStatusContext);

  const sx = React.useMemo(() => ({
    color: COLORS.sitowise.puisto
  }), [])

  if (!loadingStatus || loadingStatus === "loading") {
    return (
      <div className={loadingStatus ? "circular-progress" : "circular-progress-transparent"}>
        <CircularProgress
          disableShrink
          size={32}
          thickness={7}
          sx={sx}
        />
      </div>
    )
  }

  return (
    <div
      className="error"
    >
      <p style={{ color: COLORS.sitowise.graniitti, fontSize: 16 }}>
        {loadingStatus}
      </p>
    </div>
  )
}