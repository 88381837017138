import React, { useContext, useEffect, useRef, useState } from 'react';
import { GeoJSONFeature } from 'mapbox-gl';
import Mapbox, { Layer, NavigationControl, Source } from 'react-map-gl';
import type { MapEvent, MapMouseEvent, MapRef, MapTouchEvent, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from "react-router-dom";

import DrawControl from './DrawControl';
import { TBuildingPolygonGeojson, TBuildingPointGeojson, TMunicipalityAreasGeojson, TPostalAreasGeojson, TViewMode, TPointGeometry } from 'dippa-shared';
import { MainContext, RefContext, SelectedBuildingContext, SetContext, TooltipContext, TSelectedPolygon, TSetState } from '../Misc/Context';
import { DRAW_CONTROL_STYLES, getBuilding3dLayer, getBuildingLayer, getMunicipalityAreasLayer, getPostalAreasLayer, getSelectedBuildingLayer, getTooltipBuildingLayer } from './layers';
import { fetchBuildings } from './fetchBuildings';
import { SERVER_URL } from '../Misc/consts';
import { axiosFetch, displayAxiosError } from '../Misc/commonFunctions';
import './Map.css';
import { Header } from '../Header/Header';


const INITIAL_ZOOM = 12;
export const BUILDINGS_3D_ZOOM_BOUNDARY = 14;
export const BUILDINGS_ZOOM_BOUNDARY = 11.85;
export const MUNICIPALITIES_ZOOM_BOUNDARY = 9.99;


export type TLoadedRegions = {
  [key: string]: {
    pointFeatures: TBuildingPointGeojson["features"],
    polygonFeatures: TBuildingPolygonGeojson["features"],
    rejectCount: number
  }
}


export const Map = ({
  mapboxToken,
  setMapboxToken
}: {
  mapboxToken: string,
  setMapboxToken: TSetState<string>
}) => {
  const {
    buildingLayerFilters,
    viewMode,
    showOwnedBuildings,
    leftPanelCollapsed
  } = useContext(MainContext);
  const { selectedBuilding } = useContext(SelectedBuildingContext)
  const { drawControlRef } = useContext(RefContext);
  const tooltip = useContext(TooltipContext);
  const {
    setEnergyCalculatorOpen,
    setSelectedPolygons,
    setViewMode,
    setSelectedBuilding,
    setLoadingStatus,
    setTooltip
  } = useContext(SetContext);

  const [municipalityAreasGeojson, setMunicipalityAreasGeojson] = useState<TMunicipalityAreasGeojson>();
  const [postalAreasGeojson, setPostalAreasGeojson] = useState<TPostalAreasGeojson>();
  const [buildingsPointGeojson, setBuildingsPointGeojson] = useState<TBuildingPointGeojson>();
  const [buildingsPolygonGeojson, setBuildingsPolygonGeojson] = useState<TBuildingPolygonGeojson>();
  const mouseClickSpecs = useRef<{ lat: number, lng: number, bearing: number, pitch: number }>(undefined);
  const prevRoundBBox = useRef({ minLon: 0, minLat: 0, maxLon: 0, maxLat: 0 });
  const loadedRegions = useRef<TLoadedRegions>({});
  const predeterminedAreasFetchPending = useRef(true);
  const pendingBuildingIdAutoSelect = useRef("");
  const boundChangeTimeout = useRef<NodeJS.Timeout>(undefined);
  const isMouseDown = useRef(false);
  const mapRef = useRef<MapRef | null>(null);
  const navigate = useNavigate();

  const clearPolygons = () => {
    setSelectedPolygons({});
    drawControlRef.current?.deleteAll();
    drawControlRef.current?.changeMode("simple_select");
  }


  const changeViewMode = (mode: TViewMode) => {
    setSelectedBuilding(null);
    setEnergyCalculatorOpen(false);
    clearPolygons();
    if (mode !== "buildings") {
      setTooltip(undefined);
    }
    // else {
    //   // @ts-expect-error asdf
    //   setBuildingsPointGeojson();
    //   // @ts-expect-error asdf
    //   setBuildingsPolygonGeojson();
    // }
    setViewMode(mode)
  }


  const onMoveStart = React.useCallback(() => {
    if (viewMode === "buildings" && !showOwnedBuildings) {
      setLoadingStatus("loading");
      //setTooltip(undefined);
    }
  }, [viewMode, showOwnedBuildings]);


  const onBoundChangeTimeout = React.useCallback(() => {
    if (isMouseDown.current) {
      // Map is being dragged. onMoveEnd was emitted incorrectly.
      // Another attempt is done recursively to cover an edge case where map
      // movement is stopped by clicking or holding mouse but not moving it.
      boundChangeTimeout.current = setTimeout(onBoundChangeTimeout, 60);
      return;
    }
    if (!mapRef.current) return;
    if (mapRef.current.isMoving()) return; // Map is currently being zoomed

    const sc = mapRef.current.getBounds();
    if (!sc) return;
    const minLon = Math.floor(sc._sw.lng * 10) / 10;
    const minLat = Math.floor(sc._sw.lat * 20) / 20;
    const maxLon = Math.ceil(sc._ne.lng * 10) / 10;
    const maxLat = Math.ceil(sc._ne.lat * 20) / 20;
    if (
      minLon === prevRoundBBox.current.minLon &&
      minLat === prevRoundBBox.current.minLat &&
      maxLon === prevRoundBBox.current.maxLon &&
      maxLat === prevRoundBBox.current.maxLat
    ) {
      setLoadingStatus("");
      return;
    }

    prevRoundBBox.current.minLon = minLon;
    prevRoundBBox.current.minLat = minLat;
    prevRoundBBox.current.maxLon = maxLon;
    prevRoundBBox.current.maxLat = maxLat;
    fetchAndSetBuildingsGeojson(minLon, minLat, maxLon, maxLat);
  }, []);


  const fetchAndSetPredeterminedAreas = React.useCallback(async () => {
    if (!predeterminedAreasFetchPending.current || showOwnedBuildings) return;

    try {
      const { data: postalAreas } = await axiosFetch(`${SERVER_URL}/postal-areas.geojson`)
      const { data: municipalityAreas } = await axiosFetch(`${SERVER_URL}/municipality-areas.geojson`)
      setPostalAreasGeojson(postalAreas);
      setMunicipalityAreasGeojson(municipalityAreas);
      setLoadingStatus("");
      predeterminedAreasFetchPending.current = false;
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }
  }, [showOwnedBuildings]);


  const fetchAndSetBuildingsGeojson = React.useCallback(async (
    minLon: number,
    minLat: number,
    maxLon: number,
    maxLat: number
  ) => {
    try {
      // if (worker.current) {
      //   worker.current.terminate();
      //   worker.current = null;
      // }
      setLoadingStatus("loading");
      const { gjsonPoints, gjsonPolygons } = await fetchBuildings({ minLon, minLat, maxLon, maxLat, loadedRegions });
      // if (isMouseDown.current) {
      //   // Map move was started during data fetch. Geojson is not set to prevent stuttering
      //   console.log("Moro")
      //   return;
      // }

      fetchAndSetPredeterminedAreas();
      setBuildingsPointGeojson(gjsonPoints);
      setBuildingsPolygonGeojson(gjsonPolygons);
      setTimeout(() => {
        setLoadingStatus("");
      }, 600)

      if (pendingBuildingIdAutoSelect.current) {
        findAndSelectBuilding(gjsonPoints);
      }
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }
  }, [fetchAndSetPredeterminedAreas]);


  const onBoundChange = React.useCallback((e?: ViewStateChangeEvent | MapEvent) => {
    clearTimeout(boundChangeTimeout.current);
    if (viewMode !== "buildings" || showOwnedBuildings) return;
    if (mapRef.current && mapRef.current.getZoom() < BUILDINGS_ZOOM_BOUNDARY) return; // Edge case
    if (e?.type === "load") {
      onBoundChangeTimeout();
      return;
    }

    // NOTE: Mapbox has a bug where onMoveEnd is emitted when dragging begins.
    // Bound change is prevented with a timeout that checks if mouse is pressed down
    boundChangeTimeout.current = setTimeout(onBoundChangeTimeout, 60);
  }, [viewMode, showOwnedBuildings]);


  const onMoveEnd = React.useCallback((e?: ViewStateChangeEvent | MapEvent) => {
    if (!mapRef.current) return;
    const { lng, lat } = mapRef.current.getCenter();
    const zoom = mapRef.current.getZoom();

    navigate(`?lat=${lat}&lon=${lng}&zoom=${zoom}`, { replace: true })
    onBoundChange(e);
  }, [onBoundChange, navigate]);


  const onZoom = React.useCallback((e: ViewStateChangeEvent) => {
    if (showOwnedBuildings) {
      if (viewMode !== "buildings") {
        setViewMode("buildings");
      }
      return;
    }

    const zoom = e?.viewState?.zoom;
    if (!zoom) return;

    if (zoom >= BUILDINGS_ZOOM_BOUNDARY && viewMode !== "buildings") {
      changeViewMode("buildings");
    }
    else if (
      zoom < BUILDINGS_ZOOM_BOUNDARY &&
      zoom >= MUNICIPALITIES_ZOOM_BOUNDARY &&
      viewMode !== "postalAreas"
    ) {
      setLoadingStatus("");
      changeViewMode("postalAreas");
    }
    else if (zoom < MUNICIPALITIES_ZOOM_BOUNDARY && viewMode !== "municipalityAreas") {
      setLoadingStatus("");
      changeViewMode("municipalityAreas");
    }

  }, [showOwnedBuildings, viewMode])


  const onMouseOut = React.useCallback(() => {
    if (!mapRef.current) return
    mapRef.current.getCanvas().style.cursor = '';
    setTooltip(undefined);
  }, [])


  // TODO: Proper event type fails build compilation
  const onMouseMove = React.useCallback((e: any) => {
    if (!mapRef.current) return;
    try {
      // The getMode does fail sometimes 
      if (drawControlRef.current?.getMode() === "draw_polygon") return;
    }
    catch {
      // No-op
    }

    const feature = getViewModeFeature(e.features);
    if (!feature) {
      mapRef.current.getCanvas().style.cursor = '';
      setTooltip(undefined);
      return
    };

    if (viewMode === "buildings" && !mapRef.current.isMoving() && buildingsPointGeojson) {
      setTooltip(buildingsPointGeojson.features.find(
        // @ts-ignore
        f => feature.properties.rtunnus === f.properties.rtunnus
      ))
    }
    else {
      setTooltip(undefined);
    }

    mapRef.current.getCanvas().style.cursor = 'pointer';
  }, [viewMode, buildingsPointGeojson]);


  const onMouseDown = React.useCallback((e: MapMouseEvent | MapTouchEvent) => {
    if (!mapRef.current) return;
    setTooltip(undefined);
    const { lng, lat } = mapRef.current.getCenter();
    mouseClickSpecs.current = {
      lng: lng,
      lat: lat,
      bearing: mapRef.current.getBearing(),
      pitch: mapRef.current.getPitch()
    }
  }, []);


  // TODO: Proper event type fails build compilation
  const onMouseUp = React.useCallback((e: any) => {
    if (!mapRef.current) return;
    try {
      // The getMode does fail sometimes 
      if (drawControlRef.current?.getMode() === "draw_polygon") return;
    }
    catch {
      // No-op
    }


    const { lat, lng } = mapRef.current.getCenter()
    if (mouseClickSpecs.current?.lat !== lat
      || mouseClickSpecs.current?.lng !== lng
      || mouseClickSpecs.current?.bearing !== mapRef.current.getBearing()
      || mouseClickSpecs.current?.pitch !== mapRef.current.getPitch()
    ) {
      // Map was moved when mouse was down
      return
    }

    const feature = parseFeature(e.features)
    if (!feature) {
      // Empty area was clicked and map wasn't moved
      setSelectedBuilding(null);
      setEnergyCalculatorOpen(false);
      if (viewMode !== "buildings") {
        clearPolygons();
      }
      return
    }

    if (viewMode === "buildings") {
      // @ts-ignore
      setSelectedBuilding(feature);
      //clearPolygons();
      return
    }

    const coordinates = feature.geometry?.coordinates;
    if (!coordinates) return;

    drawControlRef.current?.add({
      // @ts-ignore
      geometry: feature.geometry,
      id: "postal-area-selection",
      properties: feature.properties,
      type: "Feature"
    })
    setSelectedPolygons({
      "postal-area-selection": {
        // @ts-ignore
        geometry: feature.geometry,
        id: "postal-area-selection",
        properties: feature.properties,
        type: "Feature"
      }
    });
  }, [viewMode, municipalityAreasGeojson, postalAreasGeojson, buildingsPointGeojson, buildingsPolygonGeojson])


  const getViewModeFeature = (features: Array<GeoJSONFeature> | undefined) => {
    if (!features) return;
    switch (viewMode) {
      case "postalAreas":
        for (const feature of features) {
          if (feature.layer?.source === "geojson-postal-areas") {
            return feature;
          }
        }
        return;
      case "municipalityAreas":
        for (const feature of features) {
          if (feature.layer?.source === "geojson-municipality-areas") {
            return feature;
          }
        }
        return;
      case "buildings":
        for (const feature of features) {
          if (mapRef.current && mapRef.current.getZoom() > BUILDINGS_3D_ZOOM_BOUNDARY) {
            if (feature.layer?.source === "geojson-3d-buildings") {
              return feature;
            }
          }
          if (feature.layer?.source === "geojson-buildings") {
            return feature;
          }
        }
    }
  }


  const parseFeature = (features: Array<GeoJSONFeature> | undefined) => {
    const feature = getViewModeFeature(features)
    if (!feature) return;
    // NOTE: Mapbox can sometimes split polygons into smaller ones
    // or lose coordinate accuracy.
    // Original feature has to be queried from geojsons
    switch (viewMode) {
      case "buildings":
        if (!feature?.properties?.rtunnus || !buildingsPointGeojson) return;
        return buildingsPointGeojson.features.find(
          // @ts-ignore
          f => f.properties.rtunnus === feature.properties.rtunnus
        )
      case "postalAreas":
        if (!feature?.properties?.postalCode || !postalAreasGeojson) return
        return postalAreasGeojson.features.find(
          // @ts-ignore
          f => f.properties.postalCode === feature.properties.postalCode
        );
      case "municipalityAreas":
        if (!feature?.properties?.finName || !municipalityAreasGeojson) return
        return municipalityAreasGeojson.features.find(
          // @ts-ignore
          f => f.properties.finName === feature.properties.finName
        );
    }
  }


  const onBuildingSearchSelect = React.useCallback((buildingId: string, pointGeojson: TPointGeometry) => {
    if (!mapRef.current) return;
    mapRef.current.jumpTo({ center: pointGeojson.coordinates, zoom: 15.5, pitch: 0, bearing: 0 });
    // mapRef.current.flyTo({ center: pointGeojson.coordinates, zoom: 16, pitch: 45, speed: 1.5, curve: 1, bearing: 0 })
    pendingBuildingIdAutoSelect.current = buildingId;
    if (buildingsPointGeojson) {
      findAndSelectBuilding(buildingsPointGeojson);
    }
  }, [buildingsPointGeojson]);


  const onPolygonUpdate = React.useCallback((e: { features: TSelectedPolygon[] }, action?: string) => {
    // Is called when polygon drawing is finished or modifying is finished
    setSelectedPolygons(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);


  const drawControlMemo = React.useMemo(() => (
    <DrawControl
      ref={drawControlRef}
      displayControlsDefault={false}
      onCreate={onPolygonUpdate}
      onUpdate={onPolygonUpdate}
      styles={DRAW_CONTROL_STYLES}
    />
  ), []);


  const findAndSelectBuilding = (geojson: TBuildingPointGeojson) => {
    const feature = geojson.features.find(
      // @ts-ignore
      f => pendingBuildingIdAutoSelect.current === f.properties.rtunnus
    )
    if (!feature) return;
    pendingBuildingIdAutoSelect.current = "";
    setSelectedBuilding(feature);
  }


  const onLoad = React.useCallback((e?: MapEvent) => {
    predeterminedAreasFetchPending.current = true;
    onBoundChange(e);
    const zoom = mapRef.current?.getZoom();
    if (zoom && zoom < BUILDINGS_ZOOM_BOUNDARY) {
      // Map was loaded and regions should be shown.
      // viewMode is changed and regions are loaded
      // @ts-expect-error
      onZoom({ viewState: { zoom: zoom } });
      fetchAndSetPredeterminedAreas();
    }
  }, [onBoundChange, fetchAndSetPredeterminedAreas, onZoom]);


  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
    }, 400)
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedBuilding(null);
        setEnergyCalculatorOpen(false);
        clearPolygons();
      }
    };
    const handleMouseDown = () => { isMouseDown.current = true };
    const handleMouseUp = () => { isMouseDown.current = false };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleKeyPress);
      // worker.current?.terminate();
      // worker.current = null;
    };
  }, []);


  useEffect(() => {
    mapRef.current?.resize();
    if (viewMode === "buildings") {
      onBoundChange();
    }
  }, [leftPanelCollapsed, viewMode]);


  useEffect(() => {
    if (!mapRef.current) return; // Map isn't ready yet

    setBuildingsPointGeojson(undefined);
    setBuildingsPolygonGeojson(undefined);
    setLoadingStatus("loading")
    if (!showOwnedBuildings) {
      prevRoundBBox.current.minLon = 0; // Forces building fetch
      onLoad();
      return;
    }

    setPostalAreasGeojson(undefined);
    setMunicipalityAreasGeojson(undefined);
    setViewMode("buildings");
    axiosFetch(`${SERVER_URL}/owned-buildings.geojson`)
      .then(({ data }) => {
        setBuildingsPointGeojson(data.gjsonPoints);
        setBuildingsPolygonGeojson(data.gjsonPolygons);
        setLoadingStatus("");
      })
      .catch((e) => {
        if (e instanceof Error) {
          setLoadingStatus(e.message);
        }
      })
  }, [showOwnedBuildings])


  const building3dLayer = React.useMemo(() => (
    getBuilding3dLayer(buildingLayerFilters, selectedBuilding)
  ), [buildingLayerFilters, selectedBuilding])


  const buildingLayer = React.useMemo(() => (
    getBuildingLayer(buildingLayerFilters, viewMode, showOwnedBuildings)
  ), [buildingLayerFilters, viewMode, showOwnedBuildings])


  const selectedBuildingLayer = React.useMemo(() => (
    getSelectedBuildingLayer(buildingLayerFilters, viewMode, showOwnedBuildings)
  ), [buildingLayerFilters, viewMode, showOwnedBuildings])


  const tooltipBuildingLayer = React.useMemo(() => (
    getTooltipBuildingLayer(buildingLayerFilters, viewMode, showOwnedBuildings)
  ), [buildingLayerFilters, viewMode, showOwnedBuildings])


  const postalAreasLayer = React.useMemo(() => (
    getPostalAreasLayer(viewMode)
  ), [viewMode])


  const municipalityAreasLayer = React.useMemo(() => (
    getMunicipalityAreasLayer(viewMode)
  ), [viewMode])


  const interactiveLayerIds = React.useMemo(() => (
    [buildingLayer.id!, building3dLayer.id!, postalAreasLayer[0].id!, municipalityAreasLayer[0].id!]
  ), [buildingLayer, building3dLayer, postalAreasLayer, municipalityAreasLayer])


  const initialViewState = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const lat = queryParams.get('lat');
    const lon = queryParams.get('lon');
    const zoom = queryParams.get('zoom');
    return {
      latitude: lat ? parseFloat(lat) : 60.18,
      longitude: lon ? parseFloat(lon) : 24.94,
      zoom: zoom ? parseFloat(zoom) : INITIAL_ZOOM
    };
  }, [])


  return (
    <Mapbox
      ref={mapRef}
      mapboxAccessToken={mapboxToken}
      initialViewState={initialViewState}
      pitchWithRotate={true}
      dragRotate={true}
      maxPitch={60}
      mapStyle={"mapbox://styles/ottoro/cm3r5wkum005001r2gl1ua8to"}
      //mapStyle={showOwnedBuildings ? "mapbox://styles/ottoro/cm3r5wkum005001r2gl1ua8to" : "mapbox://styles/ottoro/clxx3h6m1010301qr0kz18hig"}
      interactiveLayerIds={interactiveLayerIds}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onZoom={onZoom}
      //onMove={onBoundChange}
      onMoveStart={onMoveStart}
      onMoveEnd={onMoveEnd}
      onLoad={onLoad}
    // terrain={{
    //   source: "mapbox-dem",
    //   exaggeration: 1.6
    // }}
    // antialias={true}
    >
      {postalAreasGeojson ? (
        <Source
          type="geojson"
          id='geojson-postal-areas'
          data={postalAreasGeojson}
          cluster={false}
          buffer={64}
        >
          {postalAreasLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {municipalityAreasGeojson ? (
        <Source
          type="geojson"
          id='geojson-municipality-areas'
          data={municipalityAreasGeojson}
          cluster={false}
          buffer={64}
        >
          {municipalityAreasLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {buildingsPolygonGeojson ? (
        <Source
          type="geojson"
          id='geojson-3d-buildings'
          data={buildingsPolygonGeojson}
          cluster={false}
        >
          <Layer {...building3dLayer} />
        </Source>
      ) : null}

      {buildingsPointGeojson ? (
        <Source
          type="geojson"
          id='geojson-buildings'
          data={buildingsPointGeojson}
          cluster={false}
          buffer={64}
        >
          <Layer {...buildingLayer} />
        </Source>
      ) : null}

      {tooltip ? (
        <Source
          type="geojson"
          id='geojson-tooltip-building'
          data={tooltip}
          cluster={false}
        >
          {tooltipBuildingLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {selectedBuilding ? (
        <Source
          type="geojson"
          id='geojson-selected-building'
          data={selectedBuilding}
          cluster={false}
        >
          {selectedBuildingLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {/* <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.terrain-rgb"
        tileSize={128}
        maxzoom={14}
      />
      <Layer
        id="terrain"
        type="hillshade"
        source="mapbox-dem"
        paint={{
          'hillshade-exaggeration': 0.25
        }}
      /> */}


      {drawControlMemo}
      {/* <NavigationControl
        position="bottom-right"
      /> */}

      <Header
        onBuildingSearchSelect={onBuildingSearchSelect}
        setMapboxToken={setMapboxToken}
      />

      <img
        src="sitowise_logo_valkoinen.png"
        alt="Sitowise logo"
        className='sitowise-logo'
      />
    </Mapbox>
  );
};
