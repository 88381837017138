import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Checkbox, FormControlLabel, InputAdornment, Menu, MenuItem, TextField, ThemeProvider } from "@mui/material";
import { ChevronLeft, Person, Search } from "@mui/icons-material";

import { axiosFetch, interpolateColor } from "../Misc/commonFunctions";
import { COLORS, SERVER_URL } from "../Misc/consts";
import { TApi, TPointGeometry } from "dippa-shared";
import { MUI_GRAY, MUI_THEME_HEADER } from "../Misc/muiThemes";
import { MainContext, SetContext, TSetState } from "../Misc/Context";
import "./Header.css";
import { LoadingStatus } from "./LoadingStatus";
import { resetLocalStorage } from "src/App";


const BuildingsDropdown = ({
  onBuildingSelect
}: {
  onBuildingSelect: (buildingId: string, pointGeojson: TPointGeometry) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [userBuildings, setUserBuildings] = useState<Array<{
    buildingId: string,
    municipality: string,
    streetAddress: string,
    pointGeojson: TPointGeometry
  }>>();


  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose()
      return;
    }
    setAnchorEl(event.currentTarget);
  }, [anchorEl]);


  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);


  const onItemSelect = React.useCallback((buildingId: string, pointGeojson: TPointGeometry) => {
    handleClose();
    onBuildingSelect(buildingId, pointGeojson);
  }, [])


  const handleClickOutside = React.useCallback((e: MouseEvent) => {
    if (!menuRef.current) return;
    if (!menuRef.current.contains(e.target as Node)) {
      handleClose();
    }
  }, [])


  useEffect(() => {
    if (!anchorEl) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);


  useEffect(() => {
    if (!anchorEl) return;
    if (userBuildings) return; // Details are loaded just once

    axiosFetch(`${SERVER_URL}/owned-buildings.json`)
      .then(({ data }) => {
        // @ts-expect-error
        data.sort((a: string, b: string) => a.streetAddress.localeCompare(b.streetAddress))
        setUserBuildings(data)
      })
      .catch(() => {

      })
  }, [anchorEl, userBuildings]);


  const itemsMemo = React.useMemo(() => {
    if (!userBuildings) {
      return (
        <MenuItem
          disableRipple
        >
          {"Ladataan..."}
        </MenuItem>
      );
    }
    if (userBuildings.length === 0) {
      return (
        <MenuItem
          disableRipple
        >
          {"Ei omia rakennuksia"}
        </MenuItem>
      );
    }

    return userBuildings.map((building) => {
      const str = `${building.streetAddress}, ${building.municipality}, ${building.buildingId}`
      return (
        <MenuItem
          key={building.buildingId}
          onClick={() => onItemSelect(building.buildingId, building.pointGeojson)}
          disableRipple
        >
          {str}
        </MenuItem>
      )
    })
  }, [userBuildings]);


  return (
    <>
      <MenuItem
        onClick={handleClick}
        sx={{ marginLeft: -1 }}
      >
        <ChevronLeft sx={{ color: "#ffffff", fontSize: 25 }} />
        {"Omat rakennukset"}
      </MenuItem>

      <Menu
        ref={menuRef}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        component={"ul"}
        disableScrollLock={true}
        disablePortal={true}
        hideBackdrop={true}
        slotProps={{
          paper: {
            style: {
              pointerEvents: "auto",
              display: "flex",
              flexDirection: "column"
            }
          },
          root: {
            style: {
              pointerEvents: "none"
            },
          }
        }}
      >
        {itemsMemo}
      </Menu>
    </>
  )
}


const AccountMenu = ({
  setMapboxToken,
  onBuildingSearchSelect
}: {
  setMapboxToken: TSetState<string>,
  onBuildingSearchSelect: (buildingId: string, pointGeojson: TPointGeometry) => void,
}
) => {
  const { setShowOwnedBuildings } = useContext(SetContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { showOwnedBuildings } = useContext(MainContext);


  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogout = () => {
    resetLocalStorage();
    setMapboxToken('');
    handleClose();
  };


  const handleClickOutside = React.useCallback((e: MouseEvent) => {
    if (!menuRef.current) return;
    if (!menuRef.current.contains(e.target as Node)) {
      handleClose();
    }
  }, [])


  useEffect(() => {
    if (!anchorEl) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);


  const username = React.useMemo(() => localStorage.getItem("username"), []);


  const onBuildingSelect = React.useCallback((buildingId: string, pointGeojson: TPointGeometry) => {
    handleClose();
    onBuildingSearchSelect(buildingId, pointGeojson);
  }, [onBuildingSearchSelect])


  return (
    <div style={{ alignSelf: "center" }}>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        sx={{ paddingRight: 2 }}
      >
        <Person sx={{ paddingRight: 1, width: 22, height: 22 }} />
        {username}
      </Button>

      <Menu
        ref={menuRef}
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        MenuListProps={{
          "aria-labelledby": "account-button",
        }}
        disableScrollLock={true}
        disablePortal={true}
        hideBackdrop={true}
        sx={{
          transform: 'translateY(14px) translateX(8px)',
        }}
        slotProps={{
          paper: {
            style: {
              pointerEvents: "auto",
              display: "flex",
              flexDirection: "column"
            }
          },
          root: {
            style: {
              pointerEvents: "none"
            }
          }
        }}
      >
        <MenuItem onClick={() => { setShowOwnedBuildings(prev => !prev) }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showOwnedBuildings}
                sx={{ paddingLeft: 0.9, paddingTop: 0.4, paddingBottom: 0.4, paddingRight: 0.9 }}
              />
            }
            label={"Näytä omat rakennukset"}
            onClick={(e) => e.preventDefault()}
          />
        </MenuItem>
        <BuildingsDropdown
          onBuildingSelect={onBuildingSelect}
        />

        <div style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 6, paddingRight: 6 }}>
          <div style={{ width: "100%", height: "1px", backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15) }} />
        </div>

        {/* <MenuItem onClick={handleClose}>{"Vaihda salasana"}</MenuItem> */}
        <MenuItem onClick={handleLogout}>{"Kirjaudu ulos"}</MenuItem>
      </Menu>
    </div>
  )
}


const BuildingSearch = ({
  onBuildingSearchSelect
}: {
  onBuildingSearchSelect: (buildingId: string, pointGeojson: TPointGeometry) => void,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [results, setResults] = useState<TApi["building-search.json"]["response"]>([]);
  const selectedRef = useRef<HTMLLIElement | null>(null);
  const mainRef = useRef<HTMLDivElement | null>(null);

  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [error, setError] = useState("");
  const { showOwnedBuildings } = useContext(MainContext);
  const timeout = useRef<NodeJS.Timeout>(undefined);


  const onItemSelect = (buildingId: string, pointGeojson: TPointGeometry) => {
    console.log(buildingId, pointGeojson)
    setResults([]);
    setLoading(false);
    setError("");
    setQuery("");
    setActiveIndex(-1);
    onBuildingSearchSelect(buildingId, pointGeojson)
  }


  const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) => {
        const gotoFirst = prevIndex === results.length - 1;
        if (gotoFirst) {
          selectedRef.current?.parentElement?.firstElementChild?.scrollIntoView({ block: "nearest" });
          return 0;
        }

        selectedRef.current?.nextElementSibling?.scrollIntoView({ block: "nearest" });
        return prevIndex + 1;
      });

    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) => {
        const gotoLast = prevIndex === 0;
        if (gotoLast) {
          selectedRef.current?.parentElement?.lastElementChild?.scrollIntoView({ block: "nearest" });
          return results.length - 1;
        }

        selectedRef.current?.previousElementSibling?.scrollIntoView({ block: "nearest" });
        return prevIndex - 1;
      });
    } else if (e.key === 'Enter' && activeIndex !== -1) {
      onItemSelect(results[activeIndex].buildingId, results[activeIndex].pointGeojson)
    }
  }, [results, activeIndex]);


  const debouncedSearch = React.useCallback((searchTerm: string) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      if (searchTerm.trim() === '') {
        setResults([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axiosFetch(`${SERVER_URL}/building-search.json?address=${searchTerm}&showOwnedBuildings=${showOwnedBuildings}`);
        setResults(response.data);
      }
      catch (err) {
        setError('Virhe ladattaessa hakutuloksia');
      }

      setLoading(false);
    }, 150)
  }, [showOwnedBuildings]);


  useEffect(() => {
    debouncedSearch(query);
    return () => {
      clearTimeout(timeout.current);
    }
  }, [query, debouncedSearch]);


  const handleChange = (e: any) => {
    setQuery(e.target.value);
    setError("");
  };


  const handleClickOutside = (e: MouseEvent) => {
    if (!mainRef.current) return;
    if (mainRef.current.contains(e.target as Node)) {
      setActive(true);
    }
    else {
      setActiveIndex(-1);
      setActive(false);
    }
  }


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div
      ref={mainRef}
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
    >
      <TextField
        fullWidth
        onKeyDown={handleKeyDown}
        id="buildingSearch"
        //label="Hae "
        placeholder="Hae rakennusta katuosoitteella..."
        name="buildingSearch"
        value={query}
        onChange={handleChange}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: MUI_GRAY }} />
              </InputAdornment>
            )
          }
        }}
      />
      {loading && <div className="loading">Ladataan...</div>}
      {error && <div className="error">{error}</div>}
      {
        (results.length > 0 && active) ? (
          <div
            className="search-results"
          >
            {results.map((
              { buildingId, streetAddress, municipality, pointGeojson }: TApi["building-search.json"]["response"][0],
              index: number
            ) => (
              <MenuItem
                key={buildingId}
                ref={activeIndex === index ? selectedRef : null}
                selected={activeIndex === index}
                onClick={() => onItemSelect(buildingId, pointGeojson)}
                disableRipple
              >
                {`${streetAddress}, ${municipality}, ${buildingId}`}
              </MenuItem>
            ))}
          </div>
        ) : null
      }
    </div>
  )
}


export const Header = ({
  onBuildingSearchSelect,
  setMapboxToken
}: {
  onBuildingSearchSelect: (buildingId: string, pointGeojson: TPointGeometry) => void,
  setMapboxToken: TSetState<string>
}) => {

  useEffect(() => {
    // axiosFetch(`${SERVER_URL}/user-panel.json`)
    //   .then(({ data }) => {
    //     // @ts-expect-error
    //     data.buildings.sort((a: string, b: string) => a.streetAddress.localeCompare(b.streetAddress))
    //     setUserDetails(data)
    //   })
    //   .catch(() => {

    //   })
  }, []);


  return (
    <div
      className="header-wrapper"
    >
      <LoadingStatus />

      <div className="header-main">
        <ThemeProvider theme={MUI_THEME_HEADER}>
          <BuildingSearch
            onBuildingSearchSelect={onBuildingSearchSelect}
          />

          <div style={{ paddingTop: 6, paddingBottom: 6, paddingLeft: 4, paddingRight: 4 }}>
            <div style={{ width: "1px", height: "100%", backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15) }} />
          </div>

          <AccountMenu
            setMapboxToken={setMapboxToken}
            onBuildingSearchSelect={onBuildingSearchSelect}
          />
        </ThemeProvider>
      </div>
    </div >
  );
}