import React, { useState } from "react";
import axios from "axios";
import { Alert, Box, Button, TextField } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';

import { COLORS, SERVER_URL } from "./consts";
import { TSetState } from "./Context";
import { MUI_THEME_LOGIN } from "./muiThemes";
import "./Login.css"

const Login = (
  { setMapboxToken }:
    { setMapboxToken: TSetState<string> }
) => {
  const [message, setMessage] = useState('');

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    try {
      const response = await axios.post(`${SERVER_URL}/auth`, {
        username: data.get("username"),
        password: data.get("password")
      });
      if (response.data.jwtToken && response.data.mapboxToken) {
        localStorage.setItem('token', response.data.jwtToken);
        localStorage.setItem('mapboxToken', response.data.mapboxToken);
        localStorage.setItem("username", String(data.get("username")))
        setMapboxToken(response.data.mapboxToken);
        return
      }
      setMessage("Something went wrong");
    } catch (e) {
      if (e instanceof Error) {
        setMessage(e.message);
      }
    }
  };


  return (
    <ThemeProvider theme={MUI_THEME_LOGIN}>
      <div className="mui-login">
        <div style={{ marginBottom: 95, borderWidth: 3, borderStyle: "solid", borderRadius: 14, padding: 14, paddingTop: 7, color: "#ffffff" }}>
          <p style={{ fontSize: 35 }}>
            {"Energiakartta."}
          </p>
          <p style={{ fontSize: 18 }}>
            {"Beta"}
          </p>
        </div>

        <Box component="form" onSubmit={handleLogin} noValidate>
          <TextField
            required
            fullWidth
            id="username"
            label="Käyttäjätunnus"
            name="username"
            autoComplete="username"
            autoFocus
            sx={{ mb: 2.5 }}
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Salasana"
            type="password"
            id="password"
            autoComplete="password"
            sx={{ mb: 2.5 }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mb: 2.5 }}
          >
            Kirjaudu sisään
          </Button>
        </Box>

        {message && <Alert severity="error" sx={{
          borderRadius: 3,
          backgroundColor: "#00000000",
          color: COLORS.sitowise.graniitti,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: COLORS.sitowise.graniitti,
          '& .MuiAlert-icon': { color: COLORS.sitowise.graniitti }
        }}>{message}</Alert>}
      </div>
    </ThemeProvider>
  )
}


export default Login