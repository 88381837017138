import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ThemeProvider } from '@emotion/react';
import { GRANIITTI_HOVER, MUI_THEME_LOGIN } from './muiThemes';
import { Alert, Box, Button, Dialog, DialogActions, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

import { COLORS, SERVER_URL } from './consts';
import { axiosFetch, axiosPost } from './commonFunctions';
import "./AdminDashboard.css";
import { FileDropArea } from './FileDropArea';


export const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [selectedRole, setSelectedRole] = useState('user');
  const [error, setError] = useState("");


  const deleteUser = async (userId: string) => {
    try {
      setError(``);
      const token = localStorage.getItem('token');
      await axios.delete(`${SERVER_URL}/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchUsers();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error deleting user: ${JSON.stringify(e.response)}`);
      }
    }
  };


  const deleteBuildingOwners = async (userId: string) => {
    try {
      setError(``);
      const token = localStorage.getItem('token');
      await axios.delete(`${SERVER_URL}/admin/building-owners/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchUsers();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error deleting user: ${JSON.stringify(e.response)}`);
      }
    }
  };


  const fetchUsers = async () => {
    try {
      const { data } = await axiosFetch(`${SERVER_URL}/admin/users`)
      setUsers(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error fetching users: ${JSON.stringify(e.response)}`);
      }
    }
  };


  useEffect(() => {
    fetchUsers();
  }, []);


  // Register a new user
  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      setError(``);
      await axiosPost(`${SERVER_URL}/admin/users`, {
        username: data.get("username"),
        password: data.get("password"),
        role: selectedRole
      })
      fetchUsers();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error registering user: ${JSON.stringify(e.response)}`);
      }
    }
  };


  const uploadUserBuildings = async (file: File, userId: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);

    try {
      const response = await axiosPost(
        `${SERVER_URL}/admin/building-owners`,
        formData,
        { "Content-Type": "multipart/form-data" },
        "blob"
      )

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Salkutus_tulos.xlsx');
      document.body.appendChild(link);
      link.click(); // Simulate a click to trigger download
      link.remove(); // Clean up

      await fetchUsers();

      // if (response) {
      //   console.log(response.data);
      // }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }


  const handleClose = () => {
    setUserIdToDelete("");
  };


  const handleConfirm = () => {
    deleteUser(userIdToDelete)
    setUserIdToDelete("");
  };


  if (users.length === 0) return null;

  return (
    <div className='admin-dashboard'>
      <div style={{ marginTop: 20 }}>
        <h2>{"Käyttäjät"}</h2>
        {users.map((user: { _id: string, username: string, role: string, createdAt: number | Date, buildings: number }, index) => (
          <div key={user._id}>
            {index ? <div className="horizontal-divider-wrapper">
              <div className="horizontal-divider" />
            </div> : null}

            {`${user.username}, ${user.role}`}
            <br />
            {`Luotu: ${user.createdAt ? new Date(user.createdAt).toISOString() : ""}`}
            <br />
            {`Rakennuksia: ${user.buildings} kpl`}

            <div style={{ display: "flex", paddingTop: 10, paddingBottom: 10, gap: 5 }}>
              <Button
                variant="outlined"
                onClick={() => setUserIdToDelete(user._id)}
                startIcon={<DeleteForever sx={{ color: COLORS.sitowise.graniitti, fontSize: 22, padding: 0 }} />}
                sx={{
                  flex: 1,
                  fontSize: 12,
                  lineHeight: 1.1,
                  borderRadius: 2,
                  textTransform: "none",
                  textAlign: "left",
                  justifyContent: "flex-start",
                  height: 32,
                  color: COLORS.sitowise.graniitti,
                  borderColor: COLORS.sitowise.graniitti,
                  '&:hover': {
                    backgroundColor: GRANIITTI_HOVER
                  },
                  "& .MuiSvgIcon-root": {
                    color: COLORS.sitowise.graniitti,
                  }
                }}
              >
                {"Poista käyttäjä"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => deleteBuildingOwners(user._id)}
                startIcon={<DeleteForever sx={{ color: COLORS.sitowise.graniitti, fontSize: 22, padding: 0 }} />}
                sx={{
                  flex: 1,
                  fontSize: 12,
                  lineHeight: 1.1,
                  borderRadius: 2,
                  textTransform: "none",
                  textAlign: "left",
                  justifyContent: "flex-start",
                  height: 32,
                  color: COLORS.sitowise.graniitti,
                  borderColor: COLORS.sitowise.graniitti,
                  '&:hover': {
                    backgroundColor: GRANIITTI_HOVER
                  },
                  "& .MuiSvgIcon-root": {
                    color: COLORS.sitowise.graniitti,
                  }
                }}
              >
                {"Poista rakennukset"}
              </Button>
            </div>

            <FileDropArea
              onFileDrop={async (file: File) => {
                await uploadUserBuildings(file, user._id)
              }}
            />
          </div>
        ))}
      </div>

      <div style={{ display: "flex", height: 400, width: 100, backgroundColor: "#ffffff" }} >
        <p>asdfasdf</p>
      </div>

      <h2>Rekisteröi uusi käyttäjä</h2>

      <ThemeProvider theme={MUI_THEME_LOGIN}>
        <div className="mui-login">
          {/* @ts-ignore */}
          <Box component="form" onSubmit={handleRegister} noValidate>
            <TextField
              required
              fullWidth
              id="username"
              label="Käyttäjätunnus"
              name="username"
              autoComplete="username"
              autoFocus
              sx={{ mb: 2.5, mt: 2.5 }}
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Salasana"
              type="password"
              id="password"
              autoComplete="password"
              sx={{ mb: 2.5 }}
            />
            <RadioGroup value={selectedRole} onChange={(e) => { setSelectedRole(e.target.value) }} sx={{ flexDirection: "row", justifyContent: "space-around" }}>
              <FormControlLabel value="user" control={<Radio />} label="User" />
              <FormControlLabel value="admin" control={<Radio />} label="Admin" />
            </RadioGroup>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mb: 2.5, mt: 2.5 }}
            >
              Rekisteröi
            </Button>
          </Box>

          {error && <Alert severity="error" sx={{
            borderRadius: 3,
            backgroundColor: "#00000000",
            color: COLORS.sitowise.graniitti,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: COLORS.sitowise.graniitti,
            '& .MuiAlert-icon': { color: COLORS.sitowise.graniitti }
          }}>{error}</Alert>}
        </div>

        <Dialog
          open={!!userIdToDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Poista käyttäjä?"}</DialogTitle>
          {/* <div>
            <p>
              Poista käyttäjä?
            </p>
          </div> */}
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              sx={{
                backgroundColor: "#00000000",
                color: "#000000",
                borderColor: "#000000",
              }}
            >
              {"Peru"}
            </Button>
            <Button
              onClick={handleConfirm}
              variant='outlined'
              sx={{
                backgroundColor: COLORS.sitowise.graniitti,
                color: "#ffffff",
                borderColor: COLORS.sitowise.graniitti,
              }}
            >
              {"Kyllä"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};